<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">
                Bem vindo ao Ares Saúde: {{ user.name }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <b-card no-body>
                <b-card-body class="pb-0">
                  <b-avatar class="mb-1" :variant="`light-success`" size="45">
                    <i class="fas fa-user-injured" style="font-size: 24px"></i>
                  </b-avatar>
                  <div class="truncate mb-2">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ dashboard.highlights.pacientes }}
                    </h2>
                    <span>Pacientes cadastrados</span>
                  </div>
                </b-card-body>
              </b-card>
            </div>
            <div class="col-md-4">
              <b-card no-body>
                <b-card-body class="pb-0">
                  <b-avatar class="mb-1" :variant="`light-primary`" size="45">
                    <i class="fas fa-user-tie" style="font-size: 24px"></i>
                  </b-avatar>
                  <div class="truncate mb-2">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ dashboard.highlights.profissionais }}
                    </h2>
                    <span>Profissionais cadastrados</span>
                  </div>
                </b-card-body>
              </b-card>
            </div>
            <div class="col-md-4">
              <b-card no-body>
                <b-card-body class="pb-0">
                  <b-avatar class="mb-1" :variant="`light-warning`" size="45">
                    <i class="fas fa-heartbeat" style="font-size: 24px"></i>
                  </b-avatar>
                  <div class="truncate mb-2">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ dashboard.highlights.agendamentos }}
                    </h2>
                    <span>Agendamentos</span>
                  </div>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCardText, BLink } from "bootstrap-vue";

import { BCard, BCardBody, BAvatar } from "bootstrap-vue";

export default {
  data() {
    return {
      dashboard: {
        highlights: {
          pacientes: 0,
          profissionais: 0,
          agendamentos: 0,

        },
      },
      user: {},
    };
  },
  components: {
    BCard,
    BCardText,
    BLink,
    BCard,
    BCardBody,
    BAvatar,
  },
  methods: {

    getDashboard() {
      const self = this;
      const api = self.$store.state.api + "dashboard";

      axios
        .post(api)
        .then((response) => {
          self.dashboard = response.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted() {
    const self = this;
    self.getDashboard();
    self.user = self.$store.state.user;
  },
  created() { },
};
</script>

<style></style>
